import React, { useState, useEffect } from 'react';
import { Controller, useForm } from "react-hook-form";
import { Button, Form, Label, Input } from 'reactstrap';
import { FormGroup, FormControlLabel, Box, Checkbox } from '@material-ui/core';
import { NotificationManager } from 'react-notifications';
import Select from 'react-select';
import NumberFormat from 'react-number-format';
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import Main from '../Main';
import RctCollapsibleCard from '../Helpers/RctCollapsibleCard/RctCollapsibleCard';
import LinearProgress from '../Util/LinearProgress';
import * as Constants from '../Util/constants';
import * as usersAPI from '../../Api/users';
import * as Util from '../Util/util';

const EditUser = (props) => {
    const history = useHistory();
    const login = useSelector((state) => state.login);
    const company = login.company
    const companyID = company.id;
    const userID = props.location.state ? props.location.state.userID : null;

    const [loading, setLoading] = useState(false);
    const [properties, setProperties] = useState([])
    const [securityLevels, setSecurityLevels] = useState([]);
    const [userName, setUserName] = useState('');
    const [selectedRole, setSelectedRole] = useState(0);
    const [allowedComponents, setAllowedComponents] = useState([]);

    const { handleSubmit, control, setValue, formState: { errors } } = useForm();

    useEffect(() => {
        async function fetchData() {
            if (userID === null) {
                history.goBack();
                return;
            }
            setLoading(true);
            const props = await usersAPI.getPropertiesByCompany(companyID);
            const arr = [];
            for (const p of props) {
                arr.push({ value: parseInt(p.PropertyID), label: p.PropertyName })
            }
            setProperties(arr);
            setSecurityLevels(await usersAPI.getSecurityLevels())
            // get User
            const user = await usersAPI.getUser(userID);

            if (user.data !== null) {
                setValue('userFname', user.data.UserFName);
                setValue('userLname', user.data.UserLName);
                setValue('email', user.data.UserEmail);
                setValue('phone', user.data.UserPhone);
                setValue('securityLevel', user.data.SecurityLevelID);
                setSelectedRole(user.data.SecurityLevelID)
                setValue('properties', user.properties);
                if (user.data.Components && user.data.Components !== "") {
                    const coms = user.data.Components?.split(", ").map(c => parseInt(c))
                    setAllowedComponents(coms)
                } else {
                    const components = Constants.Components.filter(com => com.SecurityLevel.includes(parseInt(user.data.SecurityLevelID)))
                    setAllowedComponents(components.map(c => c.ComponentID))
                }

                setUserName(`${user.data.UserFName} ${user.data.UserLName}`)
            }
            setLoading(false);
        }
        fetchData();
    }, [companyID, userID, history, setValue])

    const submitForm = async (data) => {
        if (parseInt(data.securityLevel) === 0) {
            NotificationManager.error("Please, select the security level.", "Error.");
            return;
        }
        if (data.properties.length === 0) {
            NotificationManager.error("Please, select properties associated to this user.", "Error.");
            return;
        }
        setLoading(true);
        const res = await usersAPI.editUser({
            email: data.email,
            firstName: data.userFname,
            lastName: data.userLname,
            phone: data.phone,
            securityLevel: selectedRole,
            companyID,
            components: allowedComponents,
            properties: data.properties,
            userID
        });
        setLoading(false);
        if (res === 1) {
            NotificationManager.error(Constants.DEFAULT_ERROR, "Error");
            return;
        }
        if (res !== 0) {
            NotificationManager.error(res, "Error.");
            return;
        }
        NotificationManager.success("User updaterd!", "Success");
    }

    if (loading) {
        return (
            <RctCollapsibleCard
                colClasses="col-xs-12 col-sm-12 col-md-12"
                heading={"Loading Edit User..."}
            >
                <LinearProgress />
            </RctCollapsibleCard>
        );
    }

    return (
        <Main>
            <div className="formelements-wrapper" style={Constants.margins}>
                <div className="page-title d-flex justify-content-between align-items-center">
                    <div className="page-title-wrap">
                        <i className="ti-angle-left" style={{ cursor: 'pointer' }} onClick={() => history.goBack()}></i>
                        <h2>
                            <span>User: {userName}</span>
                        </h2>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12 col-md-12 col-xl-12">
                        <RctCollapsibleCard heading="User Details">
                            <Form onSubmit={handleSubmit(submitForm)}>
                                <div className="row">
                                    <div className="col-sm-3">
                                        <FormGroup className="mr-10 mb-10">
                                            <Label for="userFname" className="mr-sm-10">First Name</Label>
                                            <Controller
                                                name="userFname"
                                                control={control}
                                                rules={{ required: true }}
                                                render={({ field }) => (
                                                    <Input {...field} type="text" id="userFname" style={Util.setErrorStyle(errors.userFname)} />
                                                )}
                                            />
                                            {errors.userFname && (
                                                <span style={{ color: "red" }} role="alert">required</span>
                                            )}
                                        </FormGroup>
                                    </div>
                                    <div className="col-sm-3">
                                        <FormGroup className="mr-10 mb-10">
                                            <Label for="userLname" className="mr-sm-10">Last Name</Label>
                                            <Controller
                                                name="userLname"
                                                control={control}
                                                rules={{ required: true }}
                                                render={({ field }) => (
                                                    <Input {...field} type="text" id="userLname" style={Util.setErrorStyle(errors.userLname)} />
                                                )}
                                            />
                                            {errors.userLname && (
                                                <span style={{ color: "red" }} role="alert">required</span>
                                            )}
                                        </FormGroup>
                                    </div>
                                    <div className="col-sm-4">
                                        <FormGroup className="mr-10 mb-10">
                                            <Label for="email" className="mr-sm-10">Email</Label>
                                            <Controller
                                                name="email"
                                                control={control}
                                                rules={{ required: true }}
                                                render={({ field }) => (
                                                    <Input {...field} type="text" id="email" style={Util.setErrorStyle(errors.email)} />
                                                )}
                                            />
                                            {errors.email && (
                                                <span style={{ color: "red" }} role="alert">required</span>
                                            )}
                                        </FormGroup>
                                    </div>
                                    <div className="col-sm-2">
                                        <FormGroup className="mr-10 mb-10">
                                            <Label for="phone" className="mr-sm-10">Phone</Label>
                                            <Controller
                                                name="phone"
                                                control={control}
                                                rules={{ required: true }}
                                                render={({ field }) => (
                                                    <NumberFormat {...field} id="phone" format="+1 (###) ###-####" mask="_" className="form-control" style={Util.setErrorStyle(errors.phone)} />
                                                )}
                                            />
                                            {errors.phone && (
                                                <span style={{ color: "red" }} role="alert">required</span>
                                            )}
                                        </FormGroup>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-3">
                                        <FormGroup className="mr-10 mb-10">
                                            <Label for="properties" className="mr-sm-10">Properties</Label>
                                            <Controller
                                                name="properties"
                                                control={control}
                                                rules={{ required: true }}
                                                render={({ field }) => (
                                                    <Select {...field}
                                                        defaultValue={[]}
                                                        isMulti
                                                        name="properties"
                                                        options={properties}
                                                        className="basic-multi-select"
                                                        classNamePrefix="select"
                                                    />
                                                )}
                                            />
                                            {errors.properties && (
                                                <span style={{ color: "red" }} role="alert">required</span>
                                            )}
                                        </FormGroup>
                                    </div>
                                    <div className="col-sm-3">
                                        <FormGroup className="mr-10 mb-10">
                                            <Label for="securityLevel" className="mr-sm-10">Security Level</Label>
                                            <Controller
                                                name="securityLevel"
                                                control={control}
                                                rules={{ required: true }}
                                                render={({ field }) => (
                                                    <Input {...field} type="select" id="securityLevel" value={selectedRole} onChange={(e) => {
                                                        const role = parseInt(e.target.value)
                                                        setSelectedRole(role)
                                                        const components = Constants.Components.filter(com => com.SecurityLevel.includes(role))
                                                        setAllowedComponents(components.map(c => c.ComponentID))
                                                    }} style={Util.setErrorStyle(errors.securityLevel)}>
                                                        <option value="0">Select</option>
                                                        {securityLevels.map((obj, idx) =>
                                                            <option key={idx} value={obj.SecurityLevelID}>{obj.SecurityLevel}</option>
                                                        )}
                                                    </Input>
                                                )}
                                            />
                                            {errors.securityLevel && (
                                                <span style={{ color: "red" }} role="alert">required</span>
                                            )}
                                        </FormGroup>
                                    </div>
                                    <div className="col-sm-6">
                                        <Label for="escrow" className="mr-sm-10">
                                            Manage Menu Items
                                        </Label>
                                        <div className='p-2 pl-4 border rounded d-flex flex-column' style={{ borderColor: '#EBEDF2', height: '200px', overflowY: 'scroll' }}>
                                            {Constants.Components.map(component => <>
                                                <FormControlLabel
                                                    key={component.ComponentID}
                                                    label={component.ParentComponent ? component.ParentComponent + " - " + component.ComponentName : component.ComponentName}
                                                    className={component.ParentComponent ? 'ml-3' : ''}
                                                    control={
                                                        <Checkbox
                                                            checked={allowedComponents.includes(component.ComponentID)}
                                                            className='p-0'
                                                            onChange={() => {
                                                                const id = component.ComponentID;
                                                                if (!component.ParentComponent) {
                                                                    const ids = Constants.Components.filter(c => c.ParentComponent === component.ComponentName).map(c => c.ComponentID)
                                                                    if (allowedComponents.includes(id)) {
                                                                        setAllowedComponents(coms => coms.filter(d => !coms.includes(id) && !ids.includes(d)))
                                                                    } else {
                                                                        setAllowedComponents(coms => [...coms, id, ...ids])
                                                                    }

                                                                } else {
                                                                    const parent = component.ParentComponent
                                                                    const parentId = Constants.Components.find(c => c.ComponentName === parent)?.ComponentID
                                                                    if (allowedComponents.includes(id)) {
                                                                        setAllowedComponents(coms => coms.filter(c => c !== id && c !== parentId))
                                                                    }
                                                                    else {
                                                                        const ids = Constants.Components.filter(c => c.ParentComponent === parent).map(c => c.ComponentID)
                                                                        const allCheck = ids.every(dd => [...allowedComponents, id].includes(dd));
                                                                        if (allCheck) setAllowedComponents(coms => [...coms, parentId, id])
                                                                        else setAllowedComponents(coms => [...coms, id])
                                                                    }
                                                                }
                                                            }}
                                                        />
                                                    }
                                                />
                                            </>)}
                                        </div>
                                    </div>
                                </div>
                                <Button type="submit" color="primary" style={{ marginTop: '10px' }}>Update</Button>
                            </Form>
                        </RctCollapsibleCard>
                    </div>
                </div>
            </div>
        </Main>
    )
}

export default EditUser;
