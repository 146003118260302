import React, { useState, useEffect } from 'react';
import { Controller, useForm } from "react-hook-form";
import { Button, Form, Label, Input } from 'reactstrap';
import { NotificationManager } from 'react-notifications';
import { useSelector } from "react-redux";

import Main from '../Main';
import RctCollapsibleCard from '../Helpers/RctCollapsibleCard/RctCollapsibleCard';
import LinearProgress from '../Util/LinearProgress';
import * as Constants from '../Util/constants';
import * as Util from '../Util/util';
import * as companyAPI from '../../Api/company';

function EmailAllLeaseAgreements(props) {
    const login = useSelector((state) => state.login);
    const user = login.user;
    const userEmail = user.email
    const propertyID = login.selectedPropertyID;
    const properties = login.properties;

    const [ loading, setLoading ] = useState(false);
    const [propertyName, setPropertyName] = useState('');

    const { handleSubmit, control, setValue, formState: { errors }} = useForm();

    useEffect(() => {
        async function fetchData() {
            setValue('email', userEmail);
            for(const p of properties) {
                if(parseInt(p.PropertyID) === parseInt(propertyID)) {
                    setPropertyName(p.PropertyName);
                    break;
                }
            }
        }
        fetchData();
    }, [userEmail, setValue, properties, propertyID]);

    const submitForm = async (data) => {
        setLoading(true);
        const res = await companyAPI.sendLeaseAgreements({
            propertyID,
            propertyName,
            email: data.email
        });
        setLoading(false);

        if(res !== 0) {
            NotificationManager.error(Constants.DEFAULT_ERROR, "Error");
            return;
        }
        NotificationManager.success("Lease agreements sent.", "Success!")
    }

    const render = () => {
        if(loading) {
            return (
                <RctCollapsibleCard
                colClasses="col-xs-12 col-sm-12 col-md-12"
                heading={"Sending emails..."}
                >
                    <LinearProgress />
                </RctCollapsibleCard>
            );
        } else {
            const renderContent = () => {
                return (
                    <>
                        <div style={Constants.margins}>
                            <div className="row">
                                <div className="col-sm-12 col-md-12 col-xl-12">
                                    <RctCollapsibleCard heading={"Email Lease Agreements"}>
                                        <Form onSubmit={handleSubmit(submitForm)}>
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <Label for="email" className="mr-sm-10">Email</Label>
                                                    <Controller
                                                        name="email"
                                                        control={control}
                                                        rules={{ required: true }}
                                                        render={({ field }) => (
                                                            <Input {...field} type="email" id="email" style={Util.setErrorStyle(errors.email)} />
                                                        )}
                                                    />
                                                    {errors.email && (
                                                        <span style={{ color: "red" }} role="alert">required</span>
                                                    )}
                                                </div>
                                            </div>
                                            <Button type="submit" color="primary" style={{marginTop: '10px'}}>Send email</Button>
                                        </Form>
                                    </RctCollapsibleCard>
                                </div>
                            </div>
                        </div>
                    </>
                )
            }

            if(props.location !== undefined)
                return <Main>{renderContent()}</Main>;
            return renderContent();
        }
    }

    return render();
}

export default EmailAllLeaseAgreements; 