import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { MuiThemeProvider } from "@material-ui/core/styles";
import MUIDataTable from "mui-datatables";
import * as Constants from "../Util/constants";
import * as reportsAPI from "../../Api/reports";
import { dateRange, formatDate } from "./helperFunctions";
import RctCollapsibleCard from "../Helpers/RctCollapsibleCard/RctCollapsibleCard";
import LinearProgress from "../Util/LinearProgress";
import ReactApexChart from "react-apexcharts";

const VacancyMonth = (props) => {
  const [loading, setLoading] = useState(false);
  const [vacancyData, setVacancyData] = useState([]);
  const propertyName = useSelector((state) => state.login.properties);
  const [filteredName] = propertyName.filter(
    (property) => property.PropertyID === props.propertyId
  );
  const [vacancyColumns, setVacancyColumns] = useState([
    { name: "category", label: "Category" },
  ]);
  const [months, setMonths] = useState([]);
  const [monthList, setMonthList] = useState([]);
  const [state, setState] = useState({
    series: [
      {
        data: [],
      },
    ],
    options: {
      chart: {
        type: "line",
        height: 350,
      },
      xaxis: {
        type: "datetime",
        min: props.reportSearch.sDt,
        max: props.reportSearch.eDt,
      },
      stroke: {
        curve: "straight",
      },
      dataLabels: {
        enabled: false,
      },
      title: {
        text: "Vacancy",
        align: "left",
      },
      markers: {
        hover: {
          sizeOffset: 4,
        },
      },
    },
  });
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      const reportDetails = await reportsAPI.getOccupancyMonth(
        props.reportSearch
      );
      const unitsQuantity = await reportsAPI.getUnitsQuantity(
        props.reportSearch
      );
      const allMonths = dateRange(
        props.reportSearch.sDt,
        props.reportSearch.eDt
      );
      for (let i = 0; i < allMonths.length; i++) {
        months[
          monthNames[new Date(allMonths[i]).getUTCMonth()] +
            new Date(allMonths[i]).getUTCFullYear().toString()
        ] = {
          date: allMonths[i],
          value: unitsQuantity.length,
          indexMonth: new Date(allMonths[i]).getUTCMonth(),
          indexYear: new Date(allMonths[i]).getUTCFullYear(),
        };
        vacancyColumns.push({
          name:
            monthNames[new Date(allMonths[i]).getUTCMonth()] +
            new Date(allMonths[i]).getUTCFullYear().toString(),
          label:
            monthNames[new Date(allMonths[i]).getUTCMonth()] +
            " " +
            new Date(allMonths[i]).getUTCFullYear().toString(),
        });
        monthList.push(
          monthNames[new Date(allMonths[i]).getUTCMonth()] +
            new Date(allMonths[i]).getUTCFullYear().toString()
        );
      }
      for (let i = 0; i < reportDetails.length; i++) {
        if (reportDetails[i].MoveOutDate !== reportDetails[i].MoveInDate) {
          for (let j = 0; j < monthList.length; j++) {
            if (
              new Date(reportDetails[i].MoveInDate).getUTCFullYear() ===
                months[monthList[j]].indexYear &&
              new Date(reportDetails[i].MoveOutDate).getUTCFullYear() ===
                months[monthList[j]].indexYear &&
              new Date(reportDetails[i].MoveInDate).getUTCMonth() <=
                months[monthList[j]].indexMonth &&
              new Date(reportDetails[i].MoveOutDate).getUTCMonth() >
                months[monthList[j]].indexMonth
            ) {
              months[monthList[j]].value -= 1;
            }
            if (
              new Date(reportDetails[i].MoveInDate).getUTCFullYear() ===
                months[monthList[j]].indexYear &&
              new Date(reportDetails[i].MoveOutDate).getUTCFullYear() >
                months[monthList[j]].indexYear &&
              new Date(reportDetails[i].MoveInDate).getUTCMonth() <=
                months[monthList[j]].indexMonth
            ) {
              months[monthList[j]].value -= 1;
            }
            if (
              new Date(reportDetails[i].MoveInDate).getUTCFullYear() <
                months[monthList[j]].indexYear &&
              new Date(reportDetails[i].MoveOutDate).getUTCFullYear() ===
                months[monthList[j]].indexYear &&
              new Date(reportDetails[i].MoveOutDate).getUTCMonth() >
                months[monthList[j]].indexMonth
            ) {
              months[monthList[j]].value -= 1;
            }
            if (
              new Date(reportDetails[i].MoveInDate).getUTCFullYear() <
                months[monthList[j]].indexYear &&
              new Date(reportDetails[i].MoveOutDate).getUTCFullYear() >
                months[monthList[j]].indexYear
            ) {
              months[monthList[j]].value -= 1;
            }
          }
        }
      }
      state.series[0].data = monthList.map((month) => {
        return [months[month].date, months[month].value];
      });
      setVacancyColumns([
        ...vacancyColumns,
        { name: "average", label: "Average" },
      ]);
      const dataVacancy = { category: "Occupied", average: 0 };
      const dataPercent = { category: "Percent Occupied", average: 0 };
      for (let i = 1; i < vacancyColumns.length; i++) {
        const name = vacancyColumns[i].name;
        dataVacancy[name] = months[name].value;
        dataVacancy.average += months[name].value;
        dataPercent[name] =
          months[name].value.toString() +
          "/" +
          unitsQuantity.length.toString() +
          " - " +
          (Math.round(
            (months[name].value / unitsQuantity.length) * 100
          ).toString() +
            "%");
        dataPercent.average += months[name].value;
      }
      if (dataVacancy.average !== 0) {
        dataVacancy.average =
          Math.round((dataVacancy.average / monthList.length) * 100) / 100;
        dataPercent.average =
          Math.round(
            (dataPercent.average / monthList.length / unitsQuantity.length) *
              100
          ).toString() + "%";
      }
      vacancyData.push(dataVacancy);
      vacancyData.push(dataPercent);
      setLoading(false);
    }
    fetchData();
  }, []);
  if (loading) {
    return (
      <RctCollapsibleCard
        colClasses="col-xs-12 col-sm-12 col-md-12"
        heading={"Loading Reports..."}
      >
        <LinearProgress />
      </RctCollapsibleCard>
    );
  }
  return (
    <div>
      <div className="page-title d-flex justify-content-between align-items-center">
        <div className="page-title-wrap">
          <h2>
            <span>
              {props.reportName +
                " - " +
                filteredName.PropertyName +
                " - Date Range of Report: " +
                formatDate(props.reportSearch.sDt) +
                " to " +
                formatDate(props.reportSearch.eDt)}
            </span>
          </h2>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12 col-md-12 col-xl-12">
          <MuiThemeProvider theme={Constants.getMuiTheme()}>
            <MUIDataTable
              title={"Vacancy By Month"}
              data={vacancyData}
              columns={vacancyColumns}
              options={props.reportOptions}
            />
          </MuiThemeProvider>
        </div>
      </div>
      <ReactApexChart
        options={state.options}
        series={state.series}
        type="line"
        height={350}
      />
    </div>
  );
};

export default VacancyMonth;
